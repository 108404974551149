<script>
import Loader from "@/components/widgets/loader";
import Layout from "../../../layouts/main";
import VueNaverMap from "@/components/widgets/vue-naver-map";
import {apiHelpActiveMethods} from "@/state/helpers";
import store, {makeCancelablePromise} from "@/state/store";
import {formatPhone} from "@/helpers/helpers";
import {getImageUrl} from "@/helpers/api-config";
import VueNaverPano from "@/components/widgets/vue-naver-pano";
import Swal from "sweetalert2";
import Slider from "@vueform/slider";
import moment from "moment";

/**
 * Dashboard Component
 */
export default {
  components: {
    VueNaverPano,
    Layout,
    VueNaverMap,
    Loader,
    Slider
  },
  data() {
    // const
    return {
      menuId: 100200,
      menuCode: "help-active",
      isFxiedLeftOpen: false,
      mapOptions: {
        lat: 37,
        lng: 127,
        zoom: 10,
        zoomControl: true,
        zoomControlOptions: {position: 'TOP_RIGHT'},
        mapTypeControl: true,
      },
      initLayers: ['BACKGROUND', 'BACKGROUND_DETAIL', 'POI_KOREAN', 'TRANSIT', 'ENGLISH', 'CHINESE', 'JAPANESE'],
      isHelpUsersLoading: false,
      helpUsers: [],

      showHelpMeInfo: false,
      selectedMelpMe : {
        isOpenParents: true,
        isOpenChilds: true,
        isOpenHelpComplete: true,
        memoHelpComplete: '',
        locations:[],
        user: {
          phone: '',
          name : '',
        },
        family: {
          user : {
            childs : [],
            parents: []
          }
        },
      },
      helpMeData: [],
      helpMeDataNumber: 0,
      helpMeDataLocations: [],
      sliderFormat: (value) => {
        let index = parseInt(value)
        var data = this.helpMeDataLocations[index];
        if(data) {
          return data.timestamp.substring(11, 19);
        }
        return index
      },
      locationsInfowindow: null,
      addressTimer: null,

      tabIndex: 0,
      searchText: '',
      currentPage: 1,
      movePage: 1,
      lastPage: 1,

      isLoading: false,
      cancelable: null,
      naverMap: null,
      isOpenPano: false
    };
  },
  created() {
    this.$changeMenu(this.menuId, this.menuCode)
    document.title = "구조상황판";
  },
  methods: {
    ...apiHelpActiveMethods,
    onImageError(e) {
      e.target.src = require('@/assets/images/users/avatar-0.png')
    },
    formatImageUrl(width, height, photo_uid) {
      return getImageUrl(width, height, photo_uid);
    },
    formatGenderText(gender) {
      if(gender === 'Women') return '여';
      else if(gender === 'Man') return '남';
      else return ' - ';
    },
    formatPhoneText(phone) {
      return formatPhone(phone)
    },
    formatAddressShort(address) {
      if (!address) {
        return '';
      }
      let splits = address.split(" ")
      if(splits.length > 1) return splits[0] + " " + splits[1]
      return address
    },
    openFxiedLeft() {
      this.isFxiedLeftOpen = !this.isFxiedLeftOpen
    },
    initMap(naverMap) {
      this.naverMap = naverMap
      setTimeout(()=>{
        this.$refs.naverPano.initMap()
      }, 1000)

      this.locationsInfowindow = this.naverMap.createInfoWindow('')
    },
    requestFamilyData(row) {
      if(row.family) return
      if(row.isFamilyLoading) return

      row.isFamilyLoading = true

      let uid = row.uid
      store.dispatch("apiHelpActive/user", { uid }).then((result) => {
        row.family = result.data
        row.isFamilyLoading = false
      })
    },

    requestHelpUsers() {
      if(this.isHelpUsersLoading) return
      this.isHelpUsersLoading = true

      store.dispatch("apiHelpActive/list").then((result) => {
        this.helpUsers = result.data
        this.isHelpUsersLoading = false
        this.drawMarkerHelpUsers()
      })
    },
    drawMarkerHelpUsers() {
      this.helpUsers.forEach(user => {
        user.help.user = user
        let help = user.help
        this.naverMap.addUserMarker('clustering-'+user.uid, user.last_location.latitude, user.last_location.longitude, (user.photo)?user.photo.uid:null, user.name, user.phone, help, false, (data) => { this.clickHelpMeItem(data) })
      });
      this.naverMap.fitBounds([]);
      this.naverMap.loadMarkerClustering()
    },
    clickHelpMeItem(item) {
      this.helpMeDataLocations = []
      this.helpMeDataNumber = 0

      // 클러스터 숨기기
      this.naverMap.hideMarkerClustering()

      // 기존 선택 제거
      if(this.selectedMelpMe) {
        this.naverMap.removeMarkerFromId(this.selectedMelpMe.user.uid)
        this.naverMap.removeMarkerFromId(this.selectedMelpMe.uid)
      }
      // 초기화
      this.naverMap.resetBounds()

      // 선택 변경
      this.selectedMelpMe = item
      this.showHelpMeInfo = true

      // 구조요청 마커 추가
      this.naverMap.addUserMarker(item.user.uid, item.user.last_location.latitude, item.user.last_location.longitude, (item.user.photo)?item.user.photo.uid:null, item.user.name, item.user.phone, item)
      this.naverMap.addColorMarker('danger', item.uid, item.latitude, item.longitude, '구조<br/>요청')

      this.$nextTick(() => {
        // 기존 infowindow 제거
        this.locationsInfowindow.setMap(null)
      })
      // 히스토리 이미 있으면
      if(item.locations) {
        if(this.showHelpMeInfo) {
          this.drawMapLine(item)
        }
        return
      }

      this.naverMap.fitBounds([])

      // 가족정보(피보호자,보호자)
      this.requestFamilyData(this.selectedMelpMe)

      // 구조요청 이후 히스토리
      this.naverMap.clearLine()
      this.locationsInfowindow.setMap(null)
      this.naverMap.resetBounds()

      if (this.tabIndex < 2) {
        this.requestHelpLocation(this.selectedMelpMe)
      }
    },
    requestHelpComplete(row) {
      if(row.isHelpCompleteLoading) return
      if(row.status !== 'Request' && row.status !== 'Received') {
        Swal.fire('이미 처리된 건입니다.');
        return
      }

      if(!row.memoHelpComplete || row.memoHelpComplete.length < 1) {
        Swal.fire('메모 내용을 입력하세요.')
        return
      }

      if(confirm(row.user.name + '님의 구조 완료처리 하겠습니까?')) {
        row.isHelpCompleteLoading = true

        let uid = row.uid
        let params = {
          memo : row.memoHelpComplete
        }
        store.dispatch("apiHelpActive/helpComplete", { uid, params }).then((result) => {

          if(result.success) {
            Swal.fire(result.message, "", "success");
          }
          else {
            Swal.fire(result.message, "", "error");
          }
          const index = this.helpMeData.indexOf(row);
          if (index > -1) this.helpMeData.splice(index, 1)
          row.isHelpCompleteLoading = false
        })
      }
    },
    generatorLocationInfoWindowHtml(data) {
      var start = data.timestamp.substring(11, 19);
      var end = data.updated_at.substring(11, 19);
      if(data.staytime) {
        end = null;
        if(data.staytime >= 60 * 5) {
          var staytime = moment(data.timestamp).add(data.staytime, 'seconds');
          end = staytime.format('HH:mm');
        }
      }
      // var end = data.updated_at.substring(11, 16);

      var contentHtml = '';
      if (end !== null && start !== end) {
        contentHtml = '<div class="p-2 position-relative">\n' +
            '    <div>\n' +
            '        <div>#{start} ~ #{end}#{staytime}</div>\n' +
            '        <div>#{address}</div>' +
            '        <div>#{provider} #{activity_type} \n' +
            '        <div>배터리: #{battery}% | 정확도: #{accuracy}m</div>\n' +
            '    </div>';
      }
      else {
        contentHtml = '<div class="p-2">\n' +
            '    <div>\n' +
            '        <div>#{start}#{staytime}</div>\n' +
            '        <div>#{address}</div>\n' +
            '        <div>#{provider} #{activity_type} \n' +
            '        <div>배터리: #{battery}% | 정확도: #{accuracy}m</div>\n' +
            '    </div>';
      }
      contentHtml = contentHtml.replace("#{start}", start);
      contentHtml = contentHtml.replace("#{end}", end);
      if(data.staytime >= 60) contentHtml = contentHtml.replace("#{staytime}", " ("+data.staytime.toHHMM()+")");
      else contentHtml = contentHtml.replace("#{staytime}", "");
      contentHtml = contentHtml.replace("#{address}", (data.address ? data.address : '-'));
      contentHtml = contentHtml.replace("#{battery}", data.battery);
      contentHtml = contentHtml.replace("#{accuracy}", Math.round(data.accuracy));
      contentHtml = contentHtml.replace("#{provider}", data.provider);
      contentHtml = contentHtml.replace("#{activity_type}", data.activity_type);

      return contentHtml;
    },
    drawMapLine(row) {
      let locations = this.helpMeDataLocations = row.locations;
      let paths = [];
      paths.push();
      for (let i in locations) {
        let location = locations[i];
        paths.push( this.naverMap.createLatLng(location.latitude, location.longitude) );
      }
      paths.push( this.naverMap.createLatLng( row.user.last_location.latitude, row.user.last_location.longitude ));

      if(paths.length > 1) {
        this.naverMap.drawLine(paths);
      }

      this.naverMap.fitBounds(paths);
    },
    requestHelpLocation(row) {

      if(row.isLocationLoading) return
      row.isLocationLoading = true

      let uid = row.uid
      store.dispatch("apiHelpActive/locations", { uid }).then((result) => {
        row.locations = result.data
        row.isLocationLoading = false

        if(this.showHelpMeInfo) {
          this.drawMapLine(row)
          this.naverMap.fitBounds([])
        }
      })
    },
    loadData(page) {
      if(this.isLoading) return;

      if(page < 1) page = 1
      if(page > this.lastPage) page = this.lastPage

      this.currentPage = page

      let status = 'Request'
      if(this.tabIndex === 0) status = 'Request'
      else if(this.tabIndex === 1) status = 'Received'
      else if(this.tabIndex === 2) status = 'Cancel'
      else if(this.tabIndex === 3) status = 'Completed'

      let params = {
        status: status,
        keyword: this.searchText,
        page: page
      }
      this.isLoading = true;

      this.clearHelpListData(() => {
        if(this.cancelable) this.cancelable.cancel()
        this.cancelable = makeCancelablePromise(store.dispatch("apiHelpActive/users", { params }).then((result) => {
          this.lastPage = result.data.last_page;

          for(let i = 0; i < result.data.data.length; i++) {
            setTimeout(() => {
              this.helpMeData.push( result.data.data[i] );
            }, (i * 50))
          }
          this.isLoading = false;

        }))
      })
    },
    updateOpenPano(isOpen) {
      this.isOpenPano = isOpen
    },
    clearHelpListData(callback) {
      if(this.helpMeData.length === 0) {
        callback();
      } else {
        for(let i = 0; i < this.helpMeData.length; i++) {
          setTimeout(() => {
            this.helpMeData.splice(0, 1)
          }, (i * 10))
        }
        setTimeout(() => {
          callback();
        }, 200)

      }
    },
    generatorAddressLocation(data) {
      this.addressTimer = setTimeout(() => {
        let params = {
          latitude: data.latitude,
          longitude: data.longitude
        };

        store.dispatch("apiMap/coordsToAddress", params).then((result) => {
          data.address = result.data;
          this.locationsInfowindow.setContent(this.generatorLocationInfoWindowHtml(data));
          let position = this.naverMap.createLatLng(data.latitude, data.longitude)
          this.locationsInfowindow.open(this.naverMap.getMap(), position);
        });
      }, 300);
    },

  },
  mounted() {
    this.requestHelpUsers()
    this.loadData(1)
  },
  watch: {
    helpMeDataNumber(newVal, oldVal) {
      if(newVal !== oldVal) {
        var data = this.helpMeDataLocations[newVal];
        if(data) {
          let position = this.naverMap.createLatLng(data.latitude, data.longitude)
          this.naverMap.getMap().setCenter(position);
          this.locationsInfowindow.setContent(this.generatorLocationInfoWindowHtml(data));
          this.locationsInfowindow.open(this.naverMap.getMap(), position);
          clearTimeout(this.addressTimer)
          this.generatorAddressLocation(data)
          if (this.isOpenPano) {
            this.$refs.naverPano.open(data.latitude, data.longitude)
          }
        }
      }
    },
    showHelpMeInfo(newVal, oldVal) {
      if(newVal !== oldVal) {
        if(newVal) this.naverMap.hideMarkerClustering()
        else {
          if(this.selectedMelpMe) {
            this.naverMap.removeMarkerFromId(this.selectedMelpMe.user.uid)
            this.naverMap.removeMarkerFromId(this.selectedMelpMe.uid)
            this.naverMap.clearLine()
          }
          this.locationsInfowindow.setMap(null)
          this.naverMap.resetBounds()
          this.naverMap.showMarkerClustering()
        }
      }
    },
    currentPage(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.loadData(newVal)
      }
    },

    tabIndex(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.searchText = ''
        this.loadData(1)
      }
    },

  },
};
</script>

<template>
  <Layout>
    <div class="help-active fixed-layout" :class="{'fixed-open' : isFxiedLeftOpen}">
      <div class="fixed-left">
        <div class="fixed-left-close" @click="openFxiedLeft">
          <i class="fa fa-sm btn-close" v-show="isFxiedLeftOpen"></i>
          <i class="fa fa-fw fa-bars" v-show="!isFxiedLeftOpen"></i>
        </div>
        <b-tabs justified content-class="p-1 text-muted" v-model="tabIndex">
          <b-tab active>
            <template v-slot:title>
              <span class="text-danger">요청</span>
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="text-info">접수</span>
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="text-warning">취소</span>
            </template>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="text-success">완료</span>
            </template>
          </b-tab>
        </b-tabs>

        <b-form class="form-horizontal" role="form" @submit.prevent="loadData(1)">
          <b-form-group style="margin-bottom: 0 !important">
            <div class="input-group">
              <b-form-input
                  v-model="searchText"
                  for="keyword"
                  placeholder="이름,연락처"
              ></b-form-input>
              <b-button type="submit" variant="primary">
                검색
              </b-button>
            </div>

          </b-form-group>
          <div style="padding:2px 0; ">
            <ul class="pagination pagination-sm justify-content-end mt-0 mb-0">
              <li><div class="mt-1 me-1">{{currentPage}} / {{lastPage}}</div></li>
              <li class="page-item" :class="{'disabled':currentPage === 1}">
                <a href="#" class="page-link border-0" @click="loadData(--currentPage)">
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li class="ms-1 me-1">
                <b-form-input
                    v-model="currentPage"
                    type="number"
                    min="1"
                    :max="lastPage"
                    class="text-center"
                    style="width: 60px;"
                    size="sm"
                ></b-form-input>
              </li>

              <li class="page-item" :class="{'disabled':currentPage === lastPage}">
                <a href="#" class="page-link border-0" @click="loadData(++currentPage)">
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </b-form>
        <div class="list-layout simplebar-content-wrapper pb-3">
          <div class="text-center p-3" v-show="helpMeData.length === 0">조회된 내역이 없습니다.</div>
          <Loader :loading="isLoading" class="position-absolute base-list-loader"></Loader>
          <TransitionGroup name="list" tag="div">
          <div v-for="list in helpMeData" :key="list.uid" class="list-item position-relative" @click.prevent="clickHelpMeItem(list)" :class="{'selected':list.uid===((selectedMelpMe != null)?selectedMelpMe.uid:null)}">
            <div class="d-flex">
              <div class="me-1 position-relative">

                <span class="badge position-absolute" :class="{'bg-danger':list.status==='Request', 'bg-primary':list.status==='Received', 'bg-success':list.status==='Completed', 'bg-warning':list.status==='Cancel'}"
                          style="font-size:7pt;right:-2px;top:-2px;width:10px;height:10px;display: inline-block;"></span>
                <img :src="formatImageUrl(72, 72, (list.user.photo)?list.user.photo.uid:null)"
                     @error="onImageError"
                     width="72"
                     height="72"
                     class="avatar-md h-auto d-block rounded bg-dark bg-opacity-25"/>
              </div>

              <div class="align-self-center overflow-hidden me-auto">
                <div>
                  <div class="font-size-12 text-truncate">
                    <a href="#" class="text-dark">{{list.user.name}}</a>
                  </div>
                  <p class="text-muted mb-0 font-size-10">
                    {{list.user.birth_day_age}}세 (<span :class="{'text-danger' : list.user.gender === 'Women', 'text-primary': list.user.gender === 'Man'}">{{formatGenderText(list.user.gender)}}</span>)
                    / {{formatPhoneText(list.user.phone)}}
                  </p>
                  <p class="text-muted mb-0 font-size-10">{{list.created_at}}</p>
                  <p class="text-muted font-size-11 mb-0">{{formatAddressShort(list.address)}}</p>

                </div>
              </div>
            </div>
          </div>
          </TransitionGroup>
        </div>
      </div>
      <div class="fixed-right position-relative help-map" :class="{ 'show-info' : showHelpMeInfo}" style="overflow:hidden;padding-top: 10px;padding-bottom:10px;">
        <div class="position-absolute map">
          <div v-if="showHelpMeInfo && selectedMelpMe && selectedMelpMe.locations && selectedMelpMe.locations.length > 0" class="position-absolute" style="bottom:25px;left:40px;right:40px">
            <Slider v-model="helpMeDataNumber" :format="sliderFormat" :lazy="false" :min="0" :max="(selectedMelpMe && selectedMelpMe.locations && selectedMelpMe.locations.length > 0)?selectedMelpMe.locations.length-1:0" />
          </div>
          <VueNaverMap ref="naverMap" id="help-map" @initMap="initMap($event)"></VueNaverMap>
          <VueNaverPano ref="naverPano" id="pano-map" @onOpenPano="updateOpenPano($event)"></VueNaverPano>
          <b-button pill variant="primary" v-show="!isOpenPano && selectedMelpMe !== null" type="button" class="position-absolute" style="width:50px;height:50px;right:20px;bottom:40px;" @click="$refs.naverPano.open(selectedMelpMe.user.last_location.latitude, selectedMelpMe.user.last_location.longitude)"><i class="bx bx-street-view font-size-24"></i></b-button>
        </div>
        <div class="row position-absolute item-info">

          <div class="col-sm-12 col-xxl-6 mb-2">
            <button type="button" class="btn-close position-absolute" style="right:20px;top:8px;" @click.prevent="showHelpMeInfo = false"></button>
            <div class="d-flex item-info-box" style="cursor: pointer" @click.prevent="clickHelpMeItem(selectedMelpMe)">

              <div class="me-1 position-relative">

                  <span class="badge position-absolute" :class="{'bg-danger':selectedMelpMe.status==='Request', 'bg-primary':selectedMelpMe.status==='Received', 'bg-success':selectedMelpMe.status==='Completed', 'bg-warning':selectedMelpMe.status==='Cancel'}"
                        style="font-size:7pt;right:-2px;top:-2px;width:10px;height:10px;display: inline-block;"></span>
                <img :src="formatImageUrl(120, 120, (selectedMelpMe.user.photo)?selectedMelpMe.user.photo.uid:null)"
                     @error="onImageError"
                     width="120"
                     height="120"
                     class="avatar-xl h-auto d-block rounded bg-dark bg-opacity-25"/>
              </div>

              <div class="align-self-center overflow-hidden me-auto">
                <div>
                  <div class="font-size-16 text-truncate">
                    <a href="#" class="text-dark">{{selectedMelpMe.user.name}}</a>
                  </div>
                  <p class="text-muted mb-0 font-size-14">
                    {{selectedMelpMe.user.birth_day_age}}세 (<span :class="{'text-danger' : selectedMelpMe.user.gender === 'Women', 'text-primary': selectedMelpMe.user.gender === 'Man'}">{{formatGenderText(selectedMelpMe.user.gender)}}</span>)
                    / {{formatPhoneText(selectedMelpMe.user.phone)}}
                  </p>
                  <p class="text-muted mb-0 font-size-14">{{selectedMelpMe.created_at}}</p>
                  <p class="text-muted font-size-12 mb-0">{{selectedMelpMe.address}}</p>

                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-xxl-3 mb-2">
            <div class="item-info-box">
              <div class="ps-1 font-weight-semibold">보호자 목록</div>
              <div class="ps-1 pe-1 family-box">
                <div v-show="((selectedMelpMe.family && selectedMelpMe.family.user.parents)?selectedMelpMe.family.user.parents:[]).length === 0" class="text-center" style="padding-top: 20px;padding-bottom: 20px;">
                  <span v-if="selectedMelpMe.isFamilyLoading">불러오는 중...</span>
                  <span v-if="!selectedMelpMe.isFamilyLoading">보호자가 없습니다.</span>
                </div>
                <div v-for="family in (selectedMelpMe.family && selectedMelpMe.family.user.parents)?selectedMelpMe.family.user.parents:[]" :key="family.uid">
                  <div>{{formatPhoneText(family.parent.phone)}} ( {{family.parent.name}} )</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-xxl-3 mb-2">
            <div class="item-info-box">
              <div v-if="(selectedMelpMe.status === 'Cancel')" class="text-warning text-center" style="padding-top: 20px;padding-bottom: 20px;">
                구조요청 취소됨
              </div>
              <div v-if="(selectedMelpMe.status === 'Completed')" class="text-success text-center" style="padding-top: 20px;padding-bottom: 20px;">
                구조요청 완료됨
              </div>
              <div v-if="(selectedMelpMe.status === 'Request' || selectedMelpMe.status === 'Received')">
                <div class="ps-1 font-weight-semibold">구조완료 처리</div>
                <div class="ps-1 pe-1 family-box">
                  <div>
                    <b-form-textarea
                        rows="2"
                        v-model="selectedMelpMe.memoHelpComplete"
                        placeholder="메모내용을 입력하세요."
                    ></b-form-textarea></div>
                </div>
                <div class="text-center"><b-button size="sm" variant="primary" @click="requestHelpComplete(selectedMelpMe)">완료처리</b-button></div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

  </Layout>
</template>


<style scoped>
.fixed-layout {
  position:absolute;
  top: 52px;
  padding-top: 18px;
  bottom: 60px;
  right: 0;
  left: 250px;
}
.fixed-layout > div {
  vertical-align: top;
  display: inline-block;
  height:100%;
}

.fixed-left {
  position: relative;
  width: 250px;
  padding:10px;
  z-index: 101;
  transition: 0.3s ease all;
  background-color: white;
}
.fixed-right {
  width: calc(100% - 270px);
  margin-left:10px;
}
.list-layout {
  overflow: hidden scroll;
  height: calc(100% - 110px);
}

.list-item {
  padding:2px;
  width: 100%;
  border: 1px solid #dddddd;
  color: #fff;
  border-radius: 0.25rem;
  margin-bottom: 10px;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  height: 79px;
  overflow: hidden;
  cursor: pointer;
}
.list-item:hover, .list-item.selected {
  background-color: #f6f6fb;
}

.family-box {
  padding: 4px 0;
  border-top:1px solid #eeeeee;
}
.help-map .map {
  left:0;
  right: 0;
  top:10px;
  bottom:0;
  transition: all 0.5s ease;
}
.help-map.show-info .map {
  bottom:153px;
  transition: all 0.5s ease;
}
.help-map .item-info {
  overflow-x: hidden;
  overflow-y: auto;
  height:153px;
  padding-top:5px;
  bottom:-153px;
  left:0;
  right:0;
  z-index: 100;
  transition: all 0.5s ease;
  background-color: #fff
}
.help-map.show-info .item-info {
  bottom:0;
  transition: all 0.5s ease;
}
.item-info-box {
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding:4px;
  height: 100%;
  margin-bottom: 10px;
}
.item-info-box:hover .item-info-box {
  background-color: #f6f6fb;
}

.list-move, /* apply transition to moving elements */
.list-enter-active {
  transition: all 0.5s ease;
}
.list-leave-active {
  transition: all 0.1s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}

.vertical-collpsed .fixed-layout {
  left: 70px  !important;
}
.fixed-left-close {
  display: none;
  z-index: 1001;
  position: absolute;
  right:-36px;
  top:10px;
  width:37px;
  height:37px;
  padding: 10px;
  background-color: white;
  border: 1px solid #cccccc;
  border-left: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
}
@media (max-width: 992px) {
  .fixed-layout {
    left: 0  !important;
  }
}

@media (max-width: 575.98px) {
  .fixed-left {
    position: absolute;
    left: -250px;
    height:calc(100% - 18px) !important;
  }
  .fixed-left-close {
    display: block;
  }
  .fixed-left {
    border-right: 1px solid #cccccc;
  }

  .fixed-right {
    width: calc(100% - 20px);
  }
}

.fixed-open .fixed-left {
  left: 0px !important;
}

.vertical-collpsed .fixed-layout {
  left: 70px !important;
}
</style>
<style>
.help-active .nav-item .nav-link {
  padding: 5px 0px !important;
}
</style>